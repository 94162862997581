import React from "react";
import useAuth from "../hooks/useAuth";
import { useEffectOnce } from "react-use";
import { RouteComponentProps } from "@reach/router";
import Loader from "../components/Loader";
import PageContent from "../components/layouts/PageContent";
import HSeparator from "../components/HSeparator";
import SEO from "../components/SEO";
import { navigate } from "gatsby";
import NavRoutes from "../components/NavRoutes";

interface Props extends RouteComponentProps {}

function Callback({ location }: Props) {
  const { handleAuthentication } = useAuth();

  useEffectOnce(() => {
    if (!location) return;

    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication();
    } else {
      setTimeout(() => {
        navigate("/home/");
      }, 500);
    }
  });

  return (
    <>
      <NavRoutes />
      <SEO path="/callback/" noIndex />
      <HSeparator />
      <PageContent
        hasSideSheet={false}
        pageTitle="Taking you to your Skill Tree..."
      >
        <Loader />
      </PageContent>
    </>
  );
}

export default Callback;
